/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Profile, IconColor } from '@/models/profile'
import { User } from '@/models/user'
import { sha256 } from 'js-sha256'

export function mapProfile(data: Profile | undefined, user: User): Profile {
  if (data) return data

  return {
    username: user.userName,
    nickname: user.nickname,
    description: '',
    iconImage: undefined,
    backgroundImage: undefined,
    snsLink: {
      twitterId: '',
      instagramId: '',
    },
  }
}

export function mapProfileIconColor(uid: string): IconColor {
  // 1. 16進数の最大値
  const maxHex = 'f'.repeat(64)
  // 2. uidから64文字の文字列作成
  const uidHex = sha256(uid)
  // 3. 正規化(0.0〜1.0の数字にする) (1の最大値から2の値で割る)
  const hex = parseInt(uidHex, 16) / parseInt(maxHex, 16)
  // 4. 割り算しやすいように小数点をなくす
  const floor = Math.floor(hex * 10)
  // 5. アイコンの数値を取得
  const iconIndex = floor % 5

  switch (iconIndex) {
    case 0:
      return IconColor.PINK
    case 1:
      return IconColor.BLUE
    case 2:
      return IconColor.GREEN
    case 3:
      return IconColor.ORANGE
    default:
      return IconColor.KHAKI
  }
}
