export interface SnsData {
  twitterId: string
  instagramId: string
}

export interface Profile {
  username: string
  nickname: string
  description: string
  iconImage?: string
  backgroundImage?: string
  snsLink: SnsData
}

export interface PutProfileDTO {
  nickname: string
  description: string
  iconImage?: Uint8Array
  backgroundImage?: Uint8Array
  snsLink: SnsData
  needRemoveIconImage?: boolean
  needRemoveBackgroundImage?: boolean
}

// プロフィール更新時の FieldMask
export const ProfileFieldName = {
  NICKNAME: 'nickname',
  DESCRIPTION: 'description',
  SNS_LINK: 'sns_link',
  ICON_IMAGE: 'icon_image',
  BACKGROUND_IMAGE: 'background_image',
} as const
export type ProfileFieldName = typeof ProfileFieldName[keyof typeof ProfileFieldName]

export const IconColor = {
  PINK: 'pink',
  BLUE: 'blue',
  GREEN: 'green',
  ORANGE: 'orange',
  KHAKI: 'khaki',
} as const
export type IconColor = typeof IconColor[keyof typeof IconColor]
